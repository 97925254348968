<script setup>
import { defineProps,ref,defineAsyncComponent,watch,computed,nextTick  } from 'vue';
const emit = defineEmits(['update:modelValue','update']);
const {options,field} = defineProps(['options','field']);

const selectedOption = ref({id:'',name:'Selecte '+field});
const isOpen = ref(false);

const fieldOptions = ref(options)

const searchString = ref('')
const toggleDropdown = () => {
    
  isOpen.value = !isOpen.value;
  
  nextTick(() => {
        const searchInput = document.querySelector('.searchinputmake');
        if (searchInput) {
          searchInput.focus();
        } else {
          console.error('Element with class "searchinputmake" not found.');
        }
      });
};

const selectOption = ((option) => {  
  selectedOption.value = option;
  isOpen.value = false;
  emit('update:modelValue', selectedOption.value);
  emit('update', selectedOption.value);
});
const closeDropDown = ()=>{
  isOpen.value = false;
}
const filteredOptions = computed(() =>
  options.filter(year => year.name.toLowerCase().includes(searchString.value.toLowerCase()))
);
</script>
<template>   
    <div class="custom-select w-100" @mouseleave="closeDropDown()">
        <div class="selected position-relative" @click="toggleDropdown">
            <input class="position-absolute w-100 h-100 radio-input" value="new" type="radio"
                name="make" id="make" />
            <span>{{ selectedOption.name }}</span>
        </div>
        <ul v-show="isOpen" class="options">
            <li class="sticky-top bg-white">
                <input v-model="searchString" type="text" class="form-control searchinputmake border w-100" name="searchOption"/>
            </li>
            <template v-for="option in filteredOptions">
            <li :data-name="option.name" :class="{'selected':selectedOption.id==option.id}" class="position-relative">                
                <input @change="selectOption(option)" class="radio-input position-absolute w-100 h-100" :value="option.id" type="radio" name="make"
                    id="car_input" />
                {{option.name}}
              </li>
            </template>
        </ul>
    </div>

</template>
<style scoped>
.custom-select {
  position: relative;
  &:after {
    display: inline-block;
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M12 14.708L6.692 9.4l.708-.708l4.6 4.6l4.6-4.6l.708.708z'/%3E%3C/svg%3E");
  background-color: var(--bs-primary);
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
  }
  input.radio-input{
    opacity:0;
    cursor: pointer;
  }
}

.icon-color {
    width: 24px;
    height: 24px;
}

.selected {
    padding: 5px;
    background-color: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    height: 48px;    
    /* font-family: 'arial'; */
}

.options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background: white;
    z-index: 10;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    list-style: none;
    padding: 0;
    margin: 0;
}

.options li {
    padding: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    /* font-family: 'arial'; */
}

.options li:hover,.options li.selected {
    background-color: var(--bs-primary);
    color: #fff;
}

.options li span:first-child {
    margin-right: 10px;
}
</style>