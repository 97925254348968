import { createRouter, createWebHistory } from "vue-router";
import { useUtilities } from 'vuebase';
import { useCompanyProfile } from "vuebase";
import { useNavMenu } from "vuebase";
import { useProduct } from "vuebase";
import { useShopStore } from "vuebase";
import { usePages } from "vuebase";
import { useSamples } from "vuebase";
import { useCountryStore } from "vuebase";
import { useCheckoutStore } from "vuebase";
import { useGalleryStore } from "vuebase";
import { useCartStore } from "vuebase";
import { useCategories } from "vuebase";
import { useBrandStore } from "vuebase";
import { defineAsyncComponent } from "vue";
import { useAuthStore } from "vuebase";
import { useUserstore } from "vuebase";
import { useQuotationCheckout } from "vuebase";
import { useBannerStore } from "vuebase";
import { useBookingStore } from 'vuebase'
import { useCarsStore } from "vuebase";

//datalayers
import {
  GTM_page_view,
  GTM_view_item,
  GTM_view_item_list,
  GTM_view_cart,
  GTM_begin_checkout
} from "vuebase/Services/DataLayer";

interface Data {
  type: string;
}
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // if (to.name='Contact') {
    //   return { el: '#touch', behavior: "smooth" };
    // } else {
    // Default scroll to position
    return { el: '#app', top: 0, behavior: 'smooth' };
    // }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      meta: { page: 'home' },
      component: () => import('@/views/Home.vue'),
      beforeEnter: (async (to, from, next) => {
        // next();
        const tags2 = to.meta.tags;
        const banners = useBannerStore()
        await banners.getBanners(tags2);
        to.meta.banners = banners.banners.data;


        // const categories = useCategories()
        // await categories.getAllCategories({ limit: 100, featured: 1 });
        // const categoryData = categories.allCategories.data || [];
        // to.meta.data = categoryData


        next();

      })
    },
    {
      path: '/search',
      name: 'Search',
      meta: { page: 'search' },
      component: () => import('@/views/Shop.vue'),
      beforeEnter: (async (to, from, next) => {
        to.params.slug = 'shop'
        let query = to.query;
        const Shop = useShopStore();
        await Shop.getShop(query);
        const data = Shop.shop;
        to.meta.data = data;
        next();
      })
    },
    // {
    //   path: '/shop',
    //   name: 'Shop',
    //   meta: { page: 'shop' },
    //   component: () => import('@/views/Shop.vue'),
    //   beforeEnter: (async (to, from, next) => {
    //     to.params.slug = 'shop'
    //     let query = to.query;
    //     const Shop = useShopStore();
    //     await Shop.getShop(query);
    //     const data = Shop.shop;
    //     to.meta.data = data;
    //     next();
    //   })
    // },
    {
      path: '/shop',
      name: 'Shop',
      meta: { page: 'shop' },
      component: () => import('@/views/Shop.vue'),
      beforeEnter: (async (to, from, next) => {
        to.params.slug = 'shop'
        let query = to.query;
        const Shop = useShopStore();
        await Shop.getShop(query);
        const data = Shop.shop;
        to.meta.data = data;
        next();
      })
    },
    
    {
      path: "/brands/:slug",
      name: "Brand",
      meta: { page: "brand" },
      component: () => import("@/views/Brand/Brand.vue"),
      beforeEnter: async (to, from, next) => {
        const slug = to.params.slug;
        let query = to.query;
        const Brand = useBrandStore();
        await Brand.getBrand(slug, query);
        const data = Brand.data;
        to.meta.data = data;
        setTimeout(() => {
          metaheads(Brand.data.brand);
        }, 100);

        next();
      }
    },
    {
      path: "/:slug",
      name: "Product",
      meta: { page: "product" },
      component: () => import("@/views/Product/Product.vue"),
      beforeEnter: async (to, from, next) => {
        const slug = to.params.slug;
        let queryData = to.query;
        const product = useProduct();
        await product.getProduct(slug, queryData);
        let data: DataType | null = product.data;
        if (data == null) {
          // const category = useCategories();
          // await category.getCategories(slug,queryData);
          // data = category.data;
          // if (data.category != undefined) {
          next({ name: "Category", params: { slug: to.params.slug }, query: queryData });
          // } else {
          //   next({ name: "404page", params: { pathMatch: to.params.slug } });
          // }
        } else {
          const link = document.createElement("link");
          // Set the attributes for the link element
          link.rel = "preload";
          link.as = "image";
          link.type = "image/webp";
          link.fetchpriority = "high";
          link.href = data.image;
          // Append the link element to the head
          document.head.appendChild(link);
          to.meta.data = data;
          to.meta.head = {
            meta_title: data.meta_title ? data.meta_title : data.name,
            meta_description: `${data.meta_description}`,
            image: data.image
          };
          metaheads(data)
          next();
        }
        // next();
      }
    },
    {
      path: "/:slug(.*)",
      name: "Category",
      meta: { page: "category" },
      component: () => import("@/views/Category/Category.vue"),
      beforeEnter: async (to, from, next) => {
        const slug = to.params.slug;
        let queryData = to.query;
        const category = useCategories();
        await category.getCategories(slug, queryData);
        const data: DataType | null = category.data;
        setTimeout(() => {
          metaheads(data.category)
        }, 500);
        if (data.category != undefined) {
          next();
        } else {
          next({ name: "404page", params: { pathMatch: to.params.slug } });
        }
      }
    },
    {
      path: "/cart/checkout",
      name: "Checkout",
      meta: { page: "checkout" },
      component: () => import("@/views/Checkout.vue"),
      beforeEnter: async (to, from, next) => {
        if (useCartStore().itemsCount == 0 || useCartStore().itemsCount == undefined) {
          next({ name: 'Home' })
        } else {
          const Checkout = useCheckoutStore();
          await Checkout.getConfig();
          const Country = useCountryStore();
          await Country.getCountries();
          const data = Country.countries;
          to.meta.data = data;
          next();
        }
      }
    },
    {
      path: "/product-compare",
      name: "productcompare",
      meta: { page: "productcompare" },
      component: () => import("@/views/ProductCompare.vue")
    },
    {
      path: "/thank-you",
      name: "ThankYou",
      meta: { page: "thank-you" },
      component: () => import("@/views/ThankYou.vue")
    },
    {
      path: '/installation',
      name: 'Installation',
      meta: { page: "installation" },
      component: () => import('@/views/Pages/Installation.vue')
    },
    {
      path: '/child-restraint-installation',
      name: 'ChildInstallation',
      meta: { page: "child-restraint-installation" },
      component: () => import('@/views/Pages/SeatInstallation.vue')
    },
    {
      path: '/sale-help',
      name: 'SaleHelp',
      meta: { page: "sale-help" },
      component: () => import('@/views/Pages/SalesHelp.vue')
    },
    {
      path: '/tailored-fits',
      name: 'TailoredFits',
      meta: { page: "tailored-fits" },
      component: () => import('@/views/Pages/TailoredFit.vue')
    },
    {
      path: '/hire',
      name: 'HireRate',
      meta: { page: "hire" },
      component: () => import('@/views/Pages/HireRate.vue')
    },
    {
      path: '/replacement-parts',
      name: 'ReplacementParts',
      meta: { page: "replacement-parts" },
      component: () => import('@/views/Pages/Replacement.vue')
    },
    {
      path: '/services',
      name: 'Services',
      meta: { page: "services" },
      component: () => import('@/views/Pages/Services.vue')
    },
    {
      path: '/contact',
      name: 'Contact',
      meta: { page: "contact" },
      component: () => import('@/views/Pages/ContactUs.vue')
    },
    {
      path: '/about',
      name: 'About',
      meta: { page: "about" },
      component: () => import('@/views/Pages/AboutUs.vue')
    },
    {
      path: '/privacy-policy',
      name: 'Privacy Policy',
      meta: { page: "privacy-policy" },
      component: () => import('@/views/Pages/PrivacyPolicy.vue')
    },
    {
      path: '/return-policy',
      name: 'Return Policy',
      meta: { page: "return-policy" },
      component: () => import('@/views/Pages/ReturnPolicy.vue')
    },
    {
      path: '/terms-of-use',
      name: 'Terms Of Use',
      meta: { page: "terms-of-use" },
      component: () => import('@/views/Pages/TermUses.vue')
    },
    {
      path: '/shipping-policy',
      name: 'Shipping Policy',
      meta: { page: "shipping-policy" },
      component: () => import('@/views/Pages/ShippingPolicy.vue')
    },
    {
      path: '/roof-rack-world-artarmon',
      name: 'ArtarmonStore',
      meta: { page: "roof-rack-world-artarmon" },
      component: () => import('@/views/Pages/ArtarmonStore.vue')
    },
    {
      path: '/roof-rack-world-burwood',
      name: 'BurwoodStore',
      meta: { page: "roof-rack-world-burwood" },
      component: () => import('@/views/Pages/BurwoodStore.vue')
    },
    {
      path: '/cargo',
      name: 'cargo',
      component: () => import('../views/Pages/Cargo.vue')
    },
    {
      path: '/visualiser',
      name: 'Visualizer',
      meta: { page: "visualiser" },
      component: () => import('@/views/Pages/Visualizer.vue'),
      beforeEnter: async (to, from, next) => {
        try {
          const galleryStore = useGalleryStore();
          await galleryStore.getData();
          to.meta.galleryData = galleryStore;
          next();
        } catch (error) {
          console.error('Failed to fetch gallery data:', error);
          next(false);
        }
      },
    },
    {
      path: '/brands',
      name: 'BrandsAll',
      meta: { page: "brands" },
      beforeEnter: async (to, from, next) => {
        const Brands = useBrandStore()
        await Brands.getAllBrands({ limit: 100 });
        const data = Brands.brands;
        to.meta.data = data;
        next();
      },
      component: () => import('@/views/Pages/Brands.vue')
    },
    {
      path: "/:pathMatch(.*)*",
      name: "404page",
      meta: {
        head: {
          meta_title: "404 Not Found",
          meta_description: "404 Not Found",
          image: ""
        }
      },
      component: () => import("../views/404.vue")
    },
    {
      path: "/customer/profile/:tab?",
      name: "Dashboard",
      component: () => import("@/views/Customer/Dashboard.vue"),
      meta: { requiresAuth: true, page: "my-account" },
      props: (route) => ({ tab: route.params.tab || "account" }),
      beforeEnter: async (to, from, next) => {
        const link = document.createElement("link");
        // Set the attributes for the link element
        link.rel = "preload";
        link.as = "image";
        link.type = "image/webp";
        link.fetchpriority = "high";
        link.href =
          "https://cdn.flowrix.app/5113489b/uploads/2024/08/roller_blinds_shop_banner.webp";
        // Append the link element to the head
        document.head.appendChild(link);
        const userStore = useUserstore();
        await userStore.fetchUserData();
        next();
      }
    },
    {
      path: "/customer/login",
      name: "Login",
      meta: { page: "login" },
      beforeEnter: async (to, from, next) => {
        const authStore = useAuthStore()
        if (!authStore.isAuthenticated) {
          next();
        } else {
          next({ name: "Dashboard", params: { tab: 'account' } });
        }
      },
      component: () => import("@/views/Customer/Login.vue")
    },
    {
      path: "/customer/register",
      name: "Register",
      meta: { page: "sign-up" },
      beforeEnter: async (to, from, next) => {
        const CountryData = useCountryStore();
        await CountryData.getCountries();
        next();
      },
      component: () => import("@/views/Customer/Register.vue")
    },
    {
      path: "/checkout/quote/:slug",
      name: "QuotationCheckout",

      component: () => import("../views/QuotationCheckout.vue"),
      beforeEnter: async (to, from, next) => {
        const slug = to.params.slug
        const query = to.query;
        const AuthStore = useAuthStore();
        const QuotationCheckout = useQuotationCheckout();
        const isAuthenticated = AuthStore.isAuthenticated
        if (isAuthenticated) {
          await QuotationCheckout.getQuotation(slug)
          next()
        } else if (to.query.signature) {
          await QuotationCheckout.getGuestQuotation(slug, query)
          next()
        }
        else {
          next({ name: "Dashboard", params: { tab: "account" } });
        }
      }
    },
    {
      path: '/abandonedcart/:slug',
      name: 'AbandonedCart',

      component: () => import('@/views/abandonedCart.vue'),
      beforeEnter: async (to, from, next) => {
        const slug = to.params.slug
        const Checkout = useCheckoutStore()
        const CartStore = useCartStore()
        await CartStore.fetchAbandonedCart(slug)
        await Checkout.getConfig();
        const Country = useCountryStore()
        await Country.getCountries();
        next({ name: 'Checkout' })
      }
    },
    {
      path: "/customer/password/reset",
      name: "ForgetPassword",
      meta: { page: "forgotpassword" },
      component: () => import("@/views/Customer/Forget.vue")
    },
    {
      path: "/booking",
      name: "Booking",
      meta: { page: "booking" },
      component: () => import("@/views/Bookings.vue"),
      beforeEnter: async (to, from, next) => {
        const BookingStore = useBookingStore();
        await BookingStore.getCalendar(2);
        next()
      }
    },
    {
      path: '/samples',
      name: 'Samples',
      meta: { page: 'free-samples' },
      component: () => import('@/views/Sample/Samples.vue'),
      beforeEnter: async (to, from, next) => {
        to.params.slug = 'Samples'
        const query = to.query
        query.limit = 1;
        const sampleProductStore = useSamples();
        await sampleProductStore.getSamples(query.page ? query.page : 1, query);
        const data = sampleProductStore.data;
        if (data) {
          to.meta.data = data;
          next();
        } else {
          next({ name: 'ProductNotFound' });
        }
      },
    },
  ]
});

//for products
router.beforeEach(async (to, from, next) => {
  if (to.name == "Brand" && from.name == "Brand") {
    const slug = to.params.slug;
    let query = to.query;
    const Brand = useBrandStore();
    await Brand.getBrand(slug, query);
    setTimeout(() => {
      useUtilities().productloading = false
    }, 1000);
    const data = Brand.data;
    setTimeout(() => {
      metaheads(Brand.data.brand);
    }, 500);

  }
  if (to.name == "Shop" && from.name == "Shop") {
    to.params.slug = "shop";
    const Shop = useShopStore();
    let query = to.query;
    Shop.getShop(query);
    setTimeout(() => {
      useUtilities().productloading = false
    }, 1000);

    const data = Shop.shop;
    to.meta.data = data;
  }
  if (to.name == "Search" && from.name == "Search") {
    to.params.slug = "shop";
    const Shop = useShopStore();
    let query = to.query;
    Shop.getShop(query);
    setTimeout(() => {
      useUtilities().productloading = false
    }, 1000);

    const data = Shop.shop;
    to.meta.data = data;
  }
  if (to.name == "Category" && from.name == "Category") {
    const slug = to.params.slug;
    let queryData = to.query;
    const category = useCategories();
    category.getCategories(slug, queryData);
    setTimeout(() => {
      useUtilities().productloading = false
    }, 1000);
    const data: DataType | null = category.data;
    if (data.category != undefined) {
      metaheads(data.category)
    } else {
      next({ name: "404page", params: { pathMatch: to.params.slug } });
    }
  }
  if (to.name == "Samples" && from.name == "Samples") {
    const slug = "Samples";
    let queryData = to.query;
    const sampleProductStore = useSamples();
    await sampleProductStore.getSamples(queryData.page ? queryData.page : 1, queryData);
    const data = sampleProductStore.data;
    setTimeout(() => {
      useUtilities().productloading = false
    }, 1000);
    to.meta.data = data;
  }

  if (to.name == "Product" && from.name == "Product") {
    const slug = to.params.slug;
    let queryData = "";
    if (to.query && to.query.customize != "") {
      queryData = to.query;
    }
    const product = useProduct();
    await product.getProduct(slug, queryData);
    const data: DataType | null = product.data;

    if (data == null) {
      // const category = useCategories();
      // await category.getCategories(slug,queryData);
      // data = category.data;
      // if (data.category != undefined) {
      next({ name: "Category", params: { slug: to.params.slug }, query: queryData });
      // } else {
      //   next({ name: "404page", params: { pathMatch: to.params.slug } });
      // }
    } else {
      var link = document.createElement("link");
      // Set the attributes for the link element
      link.rel = "preload";
      link.as = "image";
      link.type = "image/webp";
      link.fetchpriority = "high";
      link.href = data.image;
      // Append the link element to the head
      document.head.appendChild(link);
      to.meta.data = data;
      to.meta.head = {
        meta_title: data.meta_title ? data.meta_title : data.name,
        meta_description: `${data.meta_description}`,
        image: data.image
      };
      metaheads(data)
    }
  }
  next();
});

router.beforeEach(async (to, from, next) => {
  if (to.path !== '/' && to.path.endsWith('/')) {
    // Remove the trailing slash and redirect to the new path
    const newPath = to.path.slice(0, -1);
    next({ path: newPath, query: to.query, hash: to.hash, replace: true });
  }
  // window.scrollTo({
  //   top: 0,
  //   behavior: "smooth"
  // });
  const authStore = useAuthStore();
  const CompanyProfile = useCompanyProfile();
  const NavMenu = useNavMenu();

  // if (!authStore.isAuthenticated) {
  //   await authStore.initializeAuthState();
  // }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authStore.isAuthenticated) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    next();
  }
  await Promise.all([
    CompanyProfile.getCompanyProfile(),
    NavMenu.topMenu(1, 'header'),
    NavMenu.topMenu(3, 'footer'),
  ]);

  if (to.name != 'Brand' && to.name != 'Category' && to.name != 'Product') {
    const page = usePages()
    page.page = ''
    if (page.page?.slug != to.meta.page) {
      await page.getPage(to.meta.page);
      setTimeout(() => {
        metaheads(page.page, to.name)
      }, 500);
    }
  }
});

// Function to remove existing schema scripts
function removeProductSchemaScripts() {
  const existingProductSchemas = document.querySelectorAll(
    'script[type="application/ld+json"][data-type="product-schema"]'
  );
  existingProductSchemas.forEach(script => script.remove());
}

function metaheads(data, page = '') {
  useAuthStore().errorResponseData = ''
  useUserstore().errorResponseData = ''
  let head = { name: page + ' - Roof Rack World', meta_title: page + ' - Roof Rack World', meta_description: page + ' - Roof Rack World', image: '' };
  //   const catData = useCategories().data.category;
  if (data) {
    removeProductSchemaScripts()
    // Create and append the new schema script
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.setAttribute("data-type", "product-schema"); // Mark as product-specific
    script.text = JSON.stringify({
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": data?.name,
      "image": [data?.image],
      "description": data?.description,
      "sku": data?.sku,
      "brand": {
        "@type": "Brand",
        "name": data?.brand
      },
      "offers": {
        "@type": "Offer",
        "url": data?.url,
        "priceCurrency": "USD",
        "price": data?.price,
        "availability": "https://schema.org/InStock",
        "itemCondition": "https://schema.org/NewCondition"
      }
    });

    document.head.appendChild(script);
    head.meta_title = data?.meta_title
    head.name = data?.name
    head.meta_description = data?.meta_description
    head.image = data?.image

    if (useCompanyProfile().profile?.robots == "noindex, nofollow") {
      head.robots = 'noindex, nofollow'
    } else {
      head.robots = data?.robots
    }

  }
  document.title = head?.meta_title ? head.meta_title : head.name;
  document
    .querySelector('meta[name="title"]')
    ?.setAttribute("content", head?.meta_title);
  document
    .querySelector('meta[name="description"]')
    ?.setAttribute("content", head?.meta_description);
  document.querySelector('meta[name="keywords"]')?.setAttribute("content", "");
  document
    .querySelector('meta[name="robots"]')
    ?.setAttribute("content", head.robots);
  document
    .querySelector('meta[property="og:type"]')
    ?.setAttribute("content", "website");
  document
    .querySelector('meta[property="og:title"]')
    ?.setAttribute("content", head?.meta_title);
  document
    .querySelector('meta[property="og:image"]')
    ?.setAttribute("content", head?.image);
  document
    .querySelector('meta[property="og:description"]')
    ?.setAttribute("content", head?.meta_description);
  document
    .querySelector('meta[property="og:url"]')
    ?.setAttribute("content", window.location.href);
  document
    .querySelector('link[rel="canonical"')
    ?.setAttribute("href", window.location.href);


};

router.afterEach(async (to, from, next) => {
  /*  Page View datalayer start */
  GTM_page_view(to);
  /*  Page View datalayer End */

  /*  Product page view start*/
  if (to.name == "Product") {
    const product = useProduct().data;
    if (product.type != "custom") {
      GTM_view_item(product, product.pricefloat, product.rpfloat);
    }
  }
  /* prduct page view end*/

  /* Category Datalayers Start */
  if (to.name == "Category" || to.name == "Shop") {
    var listItems = to?.meta?.data?.products?.data;
    GTM_view_item_list(listItems);
  }
  /* Category Deatalayer End */

  /*  Cart view Datalayer start */
  if (to.name == "Cart") {
    const cart = useCartStore().cart;
    GTM_view_cart(cart);
  }

  if (to.name == "Checkout") {
    const cart = useCartStore().cart;
    GTM_begin_checkout(cart);
  }
});

router.afterEach((to, from, next) => {
  var myOffcanvas = document.querySelector('#cartSidebar')
  var mob_menu = document.getElementById('MobMenu')
  var overlay = document.querySelector('.overlay')
  var navfcon = document.getElementById('menuMega')
  var body = document.getElementsByTagName('body')[0]
  var backDrop = document.querySelector('.offcanvas-backdrop')
  if (myOffcanvas) {
    myOffcanvas.classList.remove('show');
  }
  if (overlay) {

    overlay.classList.remove('active');
  }

  if (mob_menu) {
    mob_menu.style.display = 'none'
  }
  if (navfcon) {
    navfcon.style.display = 'none'
  }
  if (backDrop) {
    backDrop.remove()
    // backDrop.classList.remove('show');
  }
  // document.querySelector('.garage-sidebar').remove()
  body.removeAttribute('style')
})
export default router;
