<script setup>
import { defineProps,ref,defineAsyncComponent,watch,computed,nextTick  } from 'vue';
const emit = defineEmits(['update:modelValue','update']);
const {options,field} = defineProps(['options','field']);

const selectedOption = ref('Selecte '+field);
const isOpen = ref(false);


const searchString = ref('')
const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
  nextTick(() => {
        const searchInput = document.querySelector('.searchinputyear');
        if (searchInput) {
          searchInput.focus();
        } else {
          console.error('Element with class "searchinputmake" not found.');
        }
      });
};


const selectOption = ((option) => {  
  selectedOption.value = option;
  isOpen.value = false;
  emit('update:modelValue', selectedOption.value);
  emit('update', selectedOption.value);
});
const closeDropDown = ()=>{
  isOpen.value = false;
}
const filteredOptions = computed(() =>{
    selectedOption.value = 'Selecte '+field;
return options.filter(year => year.includes(searchString.value))
});
</script>
<template>   
    <div class="custom-select w-100" @mouseleave="closeDropDown()">
        <div class="selected position-relative" @click="toggleDropdown">
            <input class="position-absolute w-100 h-100 radio-input" value="new" type="radio"
                name="make" id="make" />
            <span>{{ selectedOption }}</span>
        </div>
        <ul v-show="isOpen" class="options">
            <li class="sticky-top bg-white">
                <input v-model="searchString" type="text" class="form-control searchinputyear border w-100" name="searchOption"/>
            </li>
            <template v-for="option in filteredOptions">
            <li :data-name="option" :class="{'selected':selectedOption==option}" class="position-relative">                
                <input @change="selectOption(option)" class="radio-input position-absolute w-100 h-100" :value="option" type="radio" name="make"
                    id="gadi" />
                {{option}}
              </li>
            </template>
        </ul>
    </div>

</template>
<style scoped>
.custom-select {
    position: relative;
    &:after {
        font-family: 'isb-icons';
        font-style: normal;
        font-variant: normal;
        font-weight: 400;
        line-height: 1;
        text-transform: none;
        content: '\e901';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(90deg);
        color: #333333;
    }

    input.radio-input {
        opacity: 0;
        cursor: pointer;
    }
}

.icon-color {
    width: 24px;
    height: 24px;
}

.selected {
    padding: 5px;
    background-color: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    height: 48px;
}

.options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background: white;
    z-index: 10;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    list-style: none;
    padding: 0;
    margin: 0;
}

.options li {
    padding: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
}

.options li:hover,.options li.selected {
    background-color: var(--bs-primary);
    color: #fff;
}

.options li span:first-child {
    margin-right: 10px;
}
</style>